import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Wrapper } from "../components/core";
import {
  SolutionDetailHeader,
  ProductsPreviewSection,
  StandOutSection,
  ListCheckBulletsSection,
  GridIconBulletsSection,
  CTASection,
} from "../components/blocks";

export default function solutionPageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.sanitySolution;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.solutionTitle} />
      <SolutionDetailHeader
        title={pageData.header?.title?.localized}
        subTitle={pageData.header?.description?.localized}
        appIconUrl={pageData.header?.iconImage?.asset?.fluid?.src}
      />

      {pageData.showComingSoon ? comingSoon() : mainSection()}

      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );

  function comingSoon() {
    return (
      <Wrapper>
        <div className={cx("w-auto md:w-8/12 m-auto", theme.spacing.sectionY)}>
          <h2 className="text-lg4 text-text1 text-center leading-none">
            {pageData.comingSoonTitle?.localized}
          </h2>
          <div className="h-30 bg-transparent" />
          <p className="text-md4 text-text2 text-center leading-loose">
            {pageData.comingSoonMessage?.localized}
          </p>
        </div>
      </Wrapper>
    );
  }

  function mainSection() {
    return (
      <>
        <ProductsPreviewSection
          title={pageData.productsSection?.leadText?.title?.localized}
          subTitle={pageData.productsSection?.leadText?.subTitle?.localized}
          descriptionRichText={pageData.productsSection?.leadText?.descriptionRichText ?? {}}
          solutions={pageData.productsSection?.products ?? []}
          backgroundColor={theme.colorsTw.bg_bg2}
        />
        <StandOutSection
          title={pageData.textBlockSection1?.leadText?.title?.localized}
          subTitle={pageData.textBlockSection1?.leadText?.subTitle?.localized}
          descriptionRichText={pageData.textBlockSection1?.leadText?.descriptionRichText ?? {}}
          coverImageUrl={pageData.textBlockSection1?.mainImage?.asset?.fluid?.src}
          backgroundColor={theme.colorsTw.bg_bg1}
          isFlipped={true}
        />
        <ListCheckBulletsSection
          title={pageData.checkBulletsList?.leadText?.title?.localized}
          subTitle={pageData.checkBulletsList?.leadText?.subTitle?.localized}
          descriptionRichText={pageData.checkBulletsList?.leadText?.descriptionRichText ?? {}}
          coverImageUrl={pageData.checkBulletsList?.mainImage?.asset?.fluid?.src}
          bulletItems={pageData.checkBulletsList?.bulletItems ?? []}
          backgroundColor={theme.colorsTw.bg_bg2}
        />
      </>
    );
  }
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String!, $id: String!) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    sanitySolution(id: { eq: $id }) {
      showComingSoon
      comingSoonTitle {
        localized(locale: $locale)
      }
      comingSoonMessage {
        localized(locale: $locale)
      }
      solutionTitle
      header {
        title {
          localized(locale: $locale)
        }
        description {
          localized(locale: $locale)
        }
        iconImage {
          ...Image
        }
      }
      productsSection {
        leadText {
          ...LeadText
        }
        products {
          productPreview {
            ...ProductPreview
          }
        }
      }
      textBlockSection1 {
        ...TextBlockSection
      }
      checkBulletsList {
        ...BulletsTextBlockSection
      }
    }
  }
`;
